import { hasAccessAtom } from "@/lib/atoms/atoms";
import { Invoice, InvoiceType } from "@/lib/types";
import { useAtomValue } from "jotai/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, GenericModal } from "../core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { switchInvoiceType } from "@/lib/adapters/invoice-adapter";

type SwitchInvoiceTypeButtonProps = {
  invoice: Invoice;
  onSuccess?: (invoice: Invoice) => void;
};

export const SwitchInvoiceTypeButton = ({
  invoice,
  onSuccess,
}: SwitchInvoiceTypeButtonProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [isVisible, setIsVisible] = useState(false);

  const handle: () => Promise<boolean> = async () => {
    return await switchInvoiceType(invoice.invoiceId, invoice.type == InvoiceType.Purchase ? InvoiceType.CreditNote : InvoiceType.Purchase)
      .then(async (res) => {
        if (onSuccess)
          onSuccess(res)
        return false;
      })
      .catch(() => true) ?? false;
  };

  return !isAdmin ? null : (
    <>
      <GenericModal
        title={t('action.switchType.invoice.title')}
        okText={t('action.switchType.invoice.ok')}
        isVisible={isVisible}
        hideModal={() => setIsVisible(false)}
        onOkHandler={handle}
        destroyOnClose
      >
        <p>{t('action.switchInvoiceType.invoice.content')}</p>
      </GenericModal>
      <CustomButton
        shape="circle"
        type="link"
        disabled={!isAdmin}
        onClick={() => setIsVisible(true)}
        icon={<FontAwesomeIcon icon="arrows-alt-h" size="sm" />}
        style={{ marginRight: 5 }}
        toolTipKey="invoiceDetail.action.switchType"
      />
    </>
  );
};
