import {
  ExtendedInvoice,
  IdentifierType,
  InvoicePayment,
  OnSuccessHandlers,
} from '@/lib/types';
import { Skeleton } from 'antd';
import { FC } from 'react';
import { NoInvoiceFoundView } from './noInvoiceFoundView';

import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  Accent,
  CustomButton,
  DocumentViewModal,
  PdfView,
} from '@/lib/components';
import { Badge, Col, Row } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditInvoice } from './editInvoice';

import {
  CmsTooltip,
  CurrencyDisplay,
  DocumentViewErrorComponent,
} from '@/lib/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ArchiveButton } from '@/lib/components/invoice-buttons/archiveButton';
import { AutoHandleButton } from '@/lib/components/invoice-buttons/autoHandleButton';
import { AutoPayButton } from '@/lib/components/invoice-buttons/autoPayButton';
import { DeleteButton } from '@/lib/components/invoice-buttons/deleteButton';
import { HandleButton } from '@/lib/components/invoice-buttons/handleButton';
import { NoteButton } from '@/lib/components/invoice-buttons/noteButton';
import { PaymentButton } from '@/lib/components/invoice-buttons/paymentButton';
import { InvoicePayments } from './invoice-payments/invoicePayments';
import { InvoiceNoteList } from './invoiceNoteList';

type InvoiceDetailProps = {
  identifierTypes: IdentifierType[];
  invoice?: ExtendedInvoice;
  invoicePayment?: InvoicePayment;
  showPdf?: boolean;
  moduleHandlers?: OnSuccessHandlers;
  isLoading: boolean;
};

export const InvoiceDetail: FC<InvoiceDetailProps> = ({
  identifierTypes,
  invoice,
  invoicePayment,
  showPdf,
  moduleHandlers,
  isLoading,
}) => {
  const { isAdmin } = useAtomValue(hasAccessAtom);
  const [editModeEnabled, setEditModeEnabled] = useState(false);

  return (
    <Skeleton loading={isLoading} active>
      {invoice ? (
        editModeEnabled ? (
          <EditInvoice
            identifierTypes={identifierTypes}
            invoice={invoice}
            disableEditMode={() => setEditModeEnabled(false)}
            onSuccess={moduleHandlers?.onEditSuccess}
          />
        ) : (
          <InvoiceViewMode
            invoiceDetails={invoice}
            showPdf={showPdf}
            onSuccessHandlers={moduleHandlers}
            invoicePayment={invoicePayment}
            isAdmin={isAdmin}
            enableEditMode={() => setEditModeEnabled(true)}
          />
        )
      ) : (
        <NoInvoiceFoundView />
      )}
    </Skeleton>
  );
};

const InvoiceViewMode = ({
  invoiceDetails,
  showPdf,
  onSuccessHandlers,
  invoicePayment,
  isAdmin,
  enableEditMode,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={40} style={{ height: '100%' }}>
      <Col sm={24} md={showPdf ? 13 : 24}>
        <Row justify="end">
          <CmsTooltip toolTipKey="invoiceDetail.label.invoicetype">
            <Badge count={t(`invoiceTypes.${invoiceDetails.type}`)} />
          </CmsTooltip>
        </Row>
        <Row justify="space-between" align="bottom">
          <Col>
            <Accent style={{ marginBottom: 10 }} color="primary">
              {t('label.invoice')}
            </Accent>
            <Accent type="h2">{invoiceDetails.invoiceNumber}</Accent>
          </Col>
          <Col>
            <Accent type="h1" color="secondary">
              <CurrencyDisplay amount={invoiceDetails.amount} />
            </Accent>
          </Col>
          <Col span={24}>{invoiceDetails.counterInvoiceRelation.name}</Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col className="button__group">
            <PaymentButton
              iconOnly={true}
              IsInPayment={invoiceDetails.isInPayment}
              invoiceId={invoiceDetails.invoiceId}
              onSuccess={onSuccessHandlers.onPaymentSuccess}
              isDisabled={!invoiceDetails.isPayable}
            />
            <DocumentViewModal
              id={invoiceDetails.sourceIdentifier}
              idType="processfile"
              data={invoiceDetails}
            />
            <NoteButton
              isDashboard={invoiceDetails.isArchived}
              invoiceId={invoiceDetails.invoiceId}
              type="default"
              color="white"
              onSuccess={onSuccessHandlers.onCommentSuccess}
            />
            <AutoHandleButton
              isAutoHandled={invoiceDetails?.isAutoHandled ?? false}
              type="default"
              color="white"
              invoiceId={invoiceDetails.invoiceId}
              onSuccess={onSuccessHandlers.onAutoHandleSuccess}
            />
            <AutoPayButton
              isAutoPaid={invoiceDetails?.isAutoPaid ?? false}
              type="default"
              color="white"
              invoiceId={invoiceDetails.invoiceId}
              onSuccess={onSuccessHandlers.onAutoPaidSuccess}
            />
            <ArchiveButton
              archive={!invoiceDetails.isArchived}
              isDashboard={!invoiceDetails.isHandled}
              type="default"
              color="white"
              invoiceId={invoiceDetails.invoiceId}
              onSuccess={onSuccessHandlers.onArchiveSuccess}
            />
            {!invoiceDetails.isArchived && (
              <HandleButton
                setHandle={!invoiceDetails.isHandled}
                type="default"
                color="white"
                invoiceId={invoiceDetails.invoiceId}
                onSuccess={onSuccessHandlers.onHandleSuccess}
              />
            )}

            {isAdmin && (
              <CustomButton
                shape="circle"
                type="link"
                disabled={!isAdmin}
                onClick={enableEditMode}
                icon={<FontAwesomeIcon icon="pencil-alt" />}
                toolTipKey="invoiceDetail.action.edit"
              />
            )}
            <DeleteButton
              invoiceId={invoiceDetails.invoiceId}
              onSuccess={onSuccessHandlers.onDeleteSuccess}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col>
            <CmsTooltip toolTipKey="invoiceDetail.label.payment">
              <Accent type="h2" color="secondary">
                {t('label.payment', {
                  count: invoicePayment?.paymentEntries?.length,
                })}
              </Accent>
            </CmsTooltip>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <InvoicePayments invoicePayment={invoicePayment} />
          </Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col>
            <CmsTooltip toolTipKey="invoiceDetail.label.comment">
              <Accent type="h2" color="secondary">
                {t('label.invoiceNote', {
                  count: invoiceDetails.notes.length,
                })}
              </Accent>
            </CmsTooltip>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <InvoiceNoteList invoiceNotes={invoiceDetails.notes} />
          </Col>
        </Row>
      </Col>
      {showPdf && (
        <Col span={24} md={11} style={{ height: '100%' }}>
          <PdfView
            url={invoiceDetails.fileUrl}
            onErrorComponent={
              <DocumentViewErrorComponent
                idType="invoice"
                data={invoiceDetails}
              />
            }
          />
        </Col>
      )}
    </Row>
  );
};
