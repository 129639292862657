import React, { useEffect, useRef, useState } from 'react';
import { pdfBackend } from './pdf-helpers';

type PdfViewProps = {
  url: string;
  onErrorComponent: React.ReactNode;
};

export const PdfView = ({ url, onErrorComponent }: PdfViewProps) => {
  const [isError, setIsError] = useState(false);

  const pdfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateView = async () => {
      try {
        await fetch(url);
        setIsError(false);
        pdfBackend().init(encodeURIComponent(url), pdfRef);
      } catch (e) {
        setIsError(true);
      }
    };
    if (url) {
      updateView();
    } else {
      setIsError(true);
    }
  }, [url]);

  return (
    <>
      {isError ? (
        onErrorComponent
      ) : (
        <div
          ref={pdfRef}
          id="viewer"
          style={{ width: '100%', height: '100%' }}
        ></div>
      )}
    </>
  );
};
