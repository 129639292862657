import { getLatestInvoice } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import {
  CustomButton,
  CustomColumnType,
  DocumentViewModal,
  EditSupplierModal,
  EditSupplierModalProps,
} from '@/lib/components';
import { PaymentSettingsTable } from '@/lib/components/tables';
import { useRelationContext } from '@/lib/context';
import { useGetPaymentSettings, useUpdatePaymentSetting } from '@/lib/queries';
import { PaymentSetting } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtomValue } from 'jotai/utils';
import { useState } from 'react';

const RESET_MODALSTATE = {
  visible: false,
  loading: false,
};
export const SupplierSettings = () => {
  const { isLoading, data: paymentSettings } = useGetPaymentSettings();

  const { isFrozen, relation } = useRelationContext();

  const hasAccess = useAtomValue(hasAccessAtom);

  const [modalState, setModalState] =
    useState<EditSupplierModalProps>(RESET_MODALSTATE);

  const onFormClose = () => setModalState(RESET_MODALSTATE);
  const { mutateAsync: updatePaymentSetting } = useUpdatePaymentSetting();
  const onFormSuccess = async (paymentSetting: PaymentSetting) => {
    if (!modalState.paymentSetting?.id) return;

    const currentPaymentSetting = modalState.paymentSetting;
    const newPaymentSetting = {
      ...currentPaymentSetting,
      ...paymentSetting,
    };

    setModalState((prev) => ({ ...prev, loading: true }));
    try {
      await updatePaymentSetting({
        id: newPaymentSetting.id,
        request: newPaymentSetting,
      });
      setModalState(RESET_MODALSTATE);
      showNotification('success', 'Succesvol uitgevoerd');
    } catch (e) {
      setModalState((prev) => ({ ...prev, loading: false }));
    }
  };

  const showForm = (paymentSetting: PaymentSetting) =>
    setModalState({
      visible: true,
      loading: false,
      paymentSetting,
    });

  const getLatestProcessFileId = async (
    paymentSetting: PaymentSetting,
  ): Promise<string> => {
    const latestInvoice = await getLatestInvoice({
      counterRelationId: paymentSetting.counterRelationId,
    });
    if (!latestInvoice) {
      throw new Error('No invoice found');
    }
    return latestInvoice.sourceIdentifier;
  };

  const additionalColumns: CustomColumnType<PaymentSetting>[] = [
    {
      title: '',
      align: 'right',
      render: (_, paymentSetting) => (
        <>
          <DocumentViewModal
            id={() => getLatestProcessFileId(paymentSetting)}
            idType="processfile"
          />
          <CustomButton
            disabled={
              !(
                hasAccess.isCustomer ||
                (hasAccess.canImpersonate && relation)
              ) ||
              hasAccess.isAccountManager ||
              isFrozen
            }
            type="link"
            shape="circle"
            onClick={() => showForm(paymentSetting)}
            toolTipKey="settingsMyNotaSuppliers.action.edit"
            icon={<FontAwesomeIcon icon="pencil-alt" />}
          />
        </>
      ),
      width: 100,
      enableDefaultSearch: false,
    },
  ];

  return (
    <>
      <PaymentSettingsTable
        isLoading={isLoading}
        additionalColumns={additionalColumns}
        items={paymentSettings}
        pagination={{ hideOnSinglePage: true }}
      />
      <EditSupplierModal
        {...modalState}
        onFormClose={onFormClose}
        onFormSuccess={onFormSuccess}
      />
    </>
  );
};
