import { setIsHandled } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Invoice, ThemeColors } from '@/lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonType } from 'antd/lib/button';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type HandleButtonProps = {
  iconOnly?: boolean;
  setHandle: boolean;
  invoiceId: number;
  color: ThemeColors;
  type: ButtonType;
  onSuccess?: (invoice: Invoice) => void;
};

export const HandleButton: FC<HandleButtonProps> = ({
  invoiceId,
  setHandle,
  iconOnly = true,
  color,
  type,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [hasAccess] = useAtom(hasAccessAtom);
  const [isLoading, setLoading] = useState(false);
  const { isFrozen } = useRelationContext();

  const onClick = async () => {
    setLoading(true);
    try {
      const invoice = await setIsHandled(invoiceId, setHandle);
      onSuccess?.(invoice);
    } finally {
      setLoading(false);
    }
  };

  const icon = setHandle ? 'eye-slash' : 'eye';
  return (
    <CustomButton
      color={color}
      type={type}
      disabled={hasAccess.isAccountManager || isFrozen}
      shape={iconOnly ? 'circle' : 'round'}
      icon={<FontAwesomeIcon icon={['far', icon]} size="sm" />}
      onClick={onClick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.handle"
    >
      {!iconOnly && (setHandle ? t('label.handle') : t('label.resetHandle'))}
    </CustomButton>
  );
};
