import { Invoice, InvoiceFilter, PagedResponse } from '@/lib/types';
import { api } from '@/lib/utils/api.axios';
import { createFilterParams } from '@/lib/utils/helpers';
import { DefinedUseQueryResult, QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiBasePaths } from '../_apiPaths';

const QUERY_KEY = ['invoices', 'invoices'];
const BASE_URL_V2 = `${apiBasePaths.invoices}/v2/invoices`;

export const invalidateInvoicesCache = async (
  client: QueryClient,
  params?: InvoiceFilter
) => {
  await client.invalidateQueries({ queryKey: [...QUERY_KEY, params] });
}

export const useGetInvoices = (
  params?: InvoiceFilter,
): DefinedUseQueryResult<PagedResponse<Invoice[]>, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () =>
      api
        .get<PagedResponse<Invoice[]>>(`${BASE_URL_V2}`, {
          params: createFilterParams(params),
        })
        .then((r) => r.data),
    enabled: !!params,
    initialData: {
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
    },
  });

export const useGetInPaymentInvoices = () =>
  useGetInvoices({ isInPayment: true });
