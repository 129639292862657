import { setArchived } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { useRelationContext } from '@/lib/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonType } from 'antd/lib/button';
import { useAtomValue } from 'jotai/utils';

import { CustomButton } from '@/lib/components';
import { Invoice, ThemeColors } from '@/lib/types';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ArchiveButtonProps = {
  iconOnly?: boolean;
  isDashboard: boolean;
  archive: boolean;
  invoiceId: number;
  color: ThemeColors;
  type: ButtonType;
  onSuccess?: (invoice: Invoice) => void;
};

export const ArchiveButton: FC<ArchiveButtonProps> = ({
  invoiceId,
  iconOnly = true,
  archive,
  color,
  type,
  onSuccess,
}) => {
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isFrozen } = useRelationContext();

  const hasAccess = useAtomValue(hasAccessAtom);

  const onclick = async () => {
    try {
      setLoading(true);
      const invoice = await setArchived(invoiceId, archive);
      onSuccess?.(invoice);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomButton
      disabled={hasAccess.isAccountManager || isFrozen}
      color={color}
      type={type}
      danger={!archive}
      shape={iconOnly ? 'circle' : 'round'}
      icon={<FontAwesomeIcon icon={['fas', 'archive']} size="sm" />}
      onClick={onclick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.archive"
    >
      {!iconOnly && (archive ? t('label.archive') : t('label.resetArchive'))}
    </CustomButton>
  );
};
