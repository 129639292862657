import { CmsTooltip } from '@/lib/components';
import { ThemeColors } from '@/lib/types';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FC } from 'react';
import styles from './styles.module.scss';

export interface CustomButtonProps extends ButtonProps {
  color?: ThemeColors;
  toolTipKey?: string;
}

export const CustomButton: FC<CustomButtonProps> = ({
  color = '',
  children,
  shape = 'round',
  toolTipKey,
  ...rest
}) => {
  const childComp = (
    <Button
      shape={shape}
      className={[styles.button, styles[color]].join(' ')}
      {...rest}
    >
      {children}
    </Button>
  );

  return <CmsTooltip toolTipKey={toolTipKey}>{childComp}</CmsTooltip>;
};
