import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Col,
  Form,
  Input,
  List,
  Popconfirm,
  Popover,
  Row,
  Tooltip,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import { Invoice, PaymentInvoiceItem } from '@/lib/types';
import {
  currencyFormatter,
  dateTimeFormatter,
  percentageFormatter,
} from '@/lib/utils/formatters';
import { NumericFormat } from 'react-number-format';
import {
  getDifferences,
  getPaymentInfo,
} from '../_helpers/paymentInvoiceHelper';
import { CreditRow } from './CreditRow';
import { PartialPaymentRow } from './PartialPaymentRow';
import styles from './styles.module.scss';

interface PaymentInvoiceProps {
  paymentInvoice: PaymentInvoiceItem;
  invoiceIndex: number;
  path: any[];
  removeInvoice: () => void;
  removeCreditInvoice: (invoice: Invoice) => void;
  handleExclude: (exclude: boolean) => void;
  isDisabled: boolean;
  isRenameable: boolean;
}

export const PaymentInvoice: FC<PaymentInvoiceProps> = ({
  paymentInvoice,
  path,
  removeInvoice,
  removeCreditInvoice,
  handleExclude,
  isDisabled,
  isRenameable,
}) => {
  const { t } = useTranslation();
  const [differences, setDifferences] = useState<string[]>([]);
  const form = Form.useFormInstance();
  const discount = Form.useWatch(['invoices', ...path, 'discount'], form);
  const toPay = Form.useWatch(['invoices', ...path, 'toPay'], form);
  const partialPayments = Form.useWatch(
    ['invoices', ...path, 'partialPayments'],
    form,
  );
  const amount = Form.useWatch(
    ['invoices', ...path, 'invoice', 'amount'],
    form,
  );
  const setToPayAmount = () => {
    if (discount === null || discount === undefined) return;
    const toPayNew = amount - amount * (discount / 100);
    if (toPayNew !== toPay) {
      form.setFieldValue(['invoices', ...path, 'toPay'], toPayNew);
    }
  };

  const setDiscount = () => {
    if (toPay === null || toPay === undefined) return;
    const discountNew = toPay > amount ? 0 : 100 - (toPay / amount) * 100;
    if (discountNew !== discount) {
      form.setFieldValue(['invoices', ...path, 'discount'], discountNew);
    }
  };

  const updateOpenstaand = () => {
    form.setFieldValue(
      ['invoices', ...path, 'partialPayments', 0, 'value'],
      toPay,
    );
  };
  const remainingValue = useMemo(() => {
    return partialPayments?.reduce((acc, val) => {
      if (!val.value) return acc;
      return acc - val.value;
    }, toPay || 0);
  }, [partialPayments, toPay]);

  const updateDifferences = () => {
    setDifferences(
      getDifferences(
        paymentInvoice.invoice,
        paymentInvoice.paymentSetting,
        partialPayments,
      ),
    );
  };

  const paymentInfo = getPaymentInfo(
    paymentInvoice.invoice,
    paymentInvoice.paymentSetting,
  );

  useEffect(() => {
    setDiscount();
    updateOpenstaand();
  }, [toPay]);
  useEffect(() => {
    setToPayAmount();
    updateOpenstaand();
  }, [discount]);
  useEffect(() => {
    updateDifferences();
  }, [paymentInvoice.partialPayments]);

  return (
    <>
      <Row gutter={10}>
        <Col span={12} className="font-scale">
          <Row gutter={10}>
            <Col span={4}>
              {dateTimeFormatter.toDateOnly(paymentInvoice.invoice.invoiceDate)}
            </Col>
            <Col span={5}>{paymentInvoice.invoice.invoiceNumber}</Col>
            <Col span={5}>
              <Form.Item name={[...path, 'receiverName']} noStyle={true}>
                <Input className={styles.dashed} disabled={!isRenameable} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <CurrencyDisplay amount={amount} />
            </Col>
            <Col span={3}>
              <Form.Item
                name={[...path, 'discount']}
                normalize={percentageFormatter().parse}
              >
                <NumericFormat
                  className={'font-scale ant-input ' + styles.dashed}
                  allowNegative={false}
                  isAllowed={(value) =>
                    !value.floatValue ||
                    (value.floatValue >= 0 && value.floatValue <= 100)
                  }
                  onValueChange={({ value }) => value}
                  decimalSeparator=","
                  fixedDecimalScale={false}
                  decimalScale={2}
                  suffix=" %"
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name={[...path, 'toPay']}
                normalize={currencyFormatter().parse}
                rules={[
                  {
                    type: 'number',
                    max: amount,
                    message: t('payment.toPayMessage'),
                  },
                ]}
              >
                <NumericFormat
                  className={styles.dashedNumber + ' font-scale'}
                  step={1}
                  allowNegative={false}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  decimalScale={2}
                  thousandSeparator="."
                  prefix="€ "
                  onValueChange={({ value }) => value}
                />
              </Form.Item>
            </Col>
          </Row>
          {paymentInvoice.nettingInvoices.length > 0 && (
            <Row gutter={10}>
              <Accent color="tertiary">{t('invoiceTypes.CreditNote')}</Accent>
            </Row>
          )}
          {paymentInvoice.nettingInvoices.map((invoice, index) => (
            <CreditRow
              key={index}
              invoice={invoice}
              remove={removeCreditInvoice}
            />
          ))}
        </Col>

        <Col span={9}>
          {paymentInvoice.isExcluded ? (
            <Badge count="Uitgesteld"></Badge>
          ) : (
            <Form.List name={[...path, 'partialPayments']}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <PartialPaymentRow
                        key={index}
                        field={field}
                        showRemove={index > 0}
                        remove={remove}
                        disabled={isDisabled}
                        paymentSetting={paymentInvoice.paymentSetting}
                        term={paymentInvoice.paymentTerm}
                      />
                    ))}
                    <Row gutter={10}>
                      <Col style={{ textAlign: 'left' }} xs={8}>
                        <CustomButton
                          color="white"
                          disabled={isDisabled}
                          shape="round"
                          toolTipKey="payment.action.addPayment"
                          onClick={() => {
                            const stillToPay = remainingValue;
                            const valueToPay = stillToPay > 0 ? stillToPay : 0;
                            add({ ...paymentInfo, value: valueToPay });
                          }}
                          icon={<FontAwesomeIcon icon="plus" />}
                        >
                          {t('payment.addPayment')}
                        </CustomButton>
                      </Col>
                      <Col
                        style={{ textAlign: 'right' }}
                        xs={14}
                        className="font-scale"
                      >
                        <Form.Item shouldUpdate noStyle>
                          {() => (
                            <>
                              <span>{t('payment.header.toPay')}: </span>
                              <CurrencyDisplay amount={toPay ?? amount} />
                              <br></br>
                              <span>{t('payment.remainingValue')}: </span>
                              <span
                                style={{
                                  color: remainingValue < 0 ? 'red' : 'black',
                                }}
                              >
                                <CurrencyDisplay amount={remainingValue} />
                              </span>
                            </>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        {toPay > amount && (
                          <Tooltip title={t('payment.toPayWarning')}>
                            <CustomButton
                              type="text"
                              shape="circle"
                              icon={
                                <FontAwesomeIcon
                                  color="red"
                                  icon="exclamation-triangle"
                                />
                              }
                            />
                          </Tooltip>
                        )}
                      </Col>
                    </Row>
                    <Row justify="end">
                      <Col flex={1}></Col>
                      <Col>
                        {remainingValue < 0 && (
                          <span
                            style={{
                              color: remainingValue < 0 ? 'red' : 'black',
                            }}
                          >
                            {t('payment.overvalued')}
                          </span>
                        )}
                      </Col>
                      <Col span={2}></Col>
                    </Row>
                  </>
                );
              }}
            </Form.List>
          )}
        </Col>
        <Col span={3} style={{ textAlign: 'right' }}>
          <>
            <DocumentViewModal
              id={paymentInvoice.invoice.sourceIdentifier}
              idType="processfile"
            />
            <Popconfirm
              title="Factuur niet meer betalen"
              onConfirm={removeInvoice}
              disabled={isDisabled}
            >
              <CustomButton
                danger
                disabled={isDisabled}
                shape="circle"
                toolTipKey="payment.action.deleteInvoice"
                icon={<FontAwesomeIcon icon="trash-alt" />}
              />
            </Popconfirm>
            <Popconfirm
              title={
                paymentInvoice.isExcluded
                  ? 'Betaling uitvoeren?'
                  : 'Betaling uitstellen?'
              }
              onConfirm={() => handleExclude(!paymentInvoice.isExcluded)}
              disabled={isDisabled}
            >
              <CustomButton
                color="default"
                shape="circle"
                toolTipKey="payment.action.excludeInvoice"
                disabled={isDisabled}
                icon={
                  <FontAwesomeIcon
                    icon={
                      paymentInvoice.isExcluded
                        ? ['fas', 'clock']
                        : ['far', 'clock']
                    }
                  />
                }
              />
            </Popconfirm>
            <Form.Item shouldUpdate>
              {() =>
                differences.length > 0 && (
                  <Popover
                    title={t('payment.differenceDetected')}
                    content={
                      <List
                        dataSource={differences}
                        split={false}
                        bordered={false}
                        size="small"
                        renderItem={(differenceKey) => (
                          <List.Item>{t(differenceKey)}</List.Item>
                        )}
                      />
                    }
                  >
                    <CustomButton
                      color="primary"
                      shape="circle"
                      toolTipKey="payment.action.showDifferences"
                      icon={<FontAwesomeIcon icon="exclamation" />}
                    />
                  </Popover>
                )
              }
            </Form.Item>
          </>
        </Col>
      </Row>
    </>
  );
};
