import { setAutoPayment } from '@/lib/adapters/invoice-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Invoice, ThemeColors } from '@/lib/types';
import { showNotification } from '@/lib/utils/showNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonType } from 'antd/lib/button';
import { useAtomValue } from 'jotai/utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IAutoPayButton {
  iconOnly?: boolean;
  invoiceId: number;
  color: ThemeColors;
  type: ButtonType;
  isAutoPaid: boolean;
  onSuccess?: (invoice: Invoice) => void;
}

export const AutoPayButton: FC<IAutoPayButton> = ({
  invoiceId,
  iconOnly = true,
  color,
  type,
  isAutoPaid,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();

  const onclick = async () => {
    try {
      setLoading(true);
      const invoice = await setAutoPayment(invoiceId, !isAutoPaid);
      onSuccess?.(invoice);
    } catch {
      showNotification(
        'error',
        'Fout tijdens activeren automatische afhandeling.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomButton
      disabled={hasAccess.isAccountManager || isFrozen}
      color={color}
      type={type}
      shape={iconOnly ? 'circle' : 'round'}
      icon={
        <div className="fa-4x">
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={['far', 'credit-card']} />

            <FontAwesomeIcon
              color={isAutoPaid ? 'grey' : 'red'}
              transform="shrink-3 up-6 right-6"
              icon={['fas', 'circle']}
            />
            <FontAwesomeIcon
              color="white"
              transform="shrink-7 up-6 right-6"
              icon={['fas', 'font']}
            />
            {isAutoPaid && (
              <FontAwesomeIcon icon={['fas', 'slash']} color="red" />
            )}
          </span>
        </div>
      }
      onClick={onclick}
      loading={isLoading}
      toolTipKey="invoiceDetail.action.autoPay"
    >
      {!iconOnly && t('label.autoPay')}
    </CustomButton>
  );
};
