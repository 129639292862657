import { getRelations } from '@/lib/adapters/users-adapter';
import {
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import { DateFilter } from '@/lib/components/datepickerFilter/dateFilter';
import { DeleteButton } from '@/lib/components/invoice-buttons/deleteButton';
import { ResendButton } from '@/lib/components/invoice-buttons/resendButton';
import { SwitchChannelButton } from '@/lib/components/invoice-buttons/switchChannelButton';
import { SwitchInvoiceTypeButton } from '@/lib/components/invoice-buttons/switchInvoiceTypeButton';
import { ScopeTrackingButton } from '@/lib/components/scope';
import { invalidateInvoicesCache, useGetInvoices } from '@/lib/queries';
import { InvoiceFilterOptions, InvoiceWithOwner } from '@/lib/types';
import { IdentifierCategory, InvoiceType, SortOrder } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { useQueryClient } from '@tanstack/react-query';
import { Col } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const baseColumnSettings: CustomColumnType<InvoiceWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const InvoiceOverview = () => {
  const { t } = useTranslation();

  const [filterOptions, setFilterOptions] = useState<InvoiceFilterOptions>({
    page: 1,
    pageSize: 10,
    from: dayjs().subtract(90, 'days'),
    to: dayjs().add(1, 'day'),
  });

  const [invoices, setInvoices] = useState<InvoiceWithOwner[]>();
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const [filter, setFilter] = useState({})

  const client = useQueryClient()
  const { data: invoiceQueryData, isLoading: queryIsLoading } = useGetInvoices(filter);

  useEffect(() => {
    setFilter({
      isArchived: filterOptions.archived,
      orderField: 'invoiceDate',
      order: SortOrder.Descending,
      dateRange: {
        from: filterOptions.from?.format('YYYY-MM-DDTHH:mm:ss'),
        to: filterOptions.to?.format('YYYY-MM-DDTHH:mm:ss'),
      },
      type: filterOptions.type === 'all' ? undefined : filterOptions.type,
      page: filterOptions.page,
      pageSize: filterOptions.pageSize,
      filterValues: filterOptions.filterValues,
    })
  }, [filterOptions])

  useEffect(() => {
    const enrichData = async () => {
      setLoading(true);
      try {
        setTotal(invoiceQueryData.total);

        const relationIds = Array.from(
          new Set(invoiceQueryData.data.map((x) => x.ownerRelationId)),
        );

        const relations = relationIds.length
          ? (
            await getRelations({
              relationIds: relationIds,
            })
          )?.data
          : [];

        const invoicesWithOwners: InvoiceWithOwner[] =
          invoiceQueryData.data.map((invoice) => ({
            ...invoice,
            owner: relations.find(
              (relation) => relation.id === invoice.ownerRelationId,
            ),
          }));

        setInvoices(invoicesWithOwners);
      } catch {
        showNotification('error', 'Fout tijdens ophalen facturen');
      } finally {
        setLoading(false);
      }
    };

    enrichData();
  }, [invoiceQueryData]);

  const onSuccess = async () => {
    await invalidateInvoicesCache(client, filter)
  }

  const onTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      filterOptions.pageSize,
      'invoiceDate',
    );

    setFilterOptions((current) => ({ ...current, ...filter }));
    setLoading(false);
  };

  const tableColumns: CustomColumnType<InvoiceWithOwner>[] = [
    {
      ...baseColumnSettings,
      title: t('renders.invoice.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: InvoiceWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('renders.invoice.owner.name'),
      dataIndex: 'ownerName',
      render: (_, row: InvoiceWithOwner) => {
        return row.owner?.name;
      },
      sorter: false,
    },
    {
      title: t('renders.invoice.customer'),
      dataIndex: 'customerInvoiceRelation.name',
      render: (_, row: InvoiceWithOwner) => {
        return row.customer?.name;
      },
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.type'),
      dataIndex: 'type',
      render: (_, row: InvoiceWithOwner) => {
        return t('invoiceTypes.' + row.type);
      },
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.details.invoiceNumber'),
      dataIndex: 'invoiceNumber',
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.details.invoiceDate'),
      dataIndex: 'invoiceDate',
      defaultRender: 'dateonly',
      ...baseColumnSettings,
      defaultSearch: 'dateonly',
    },
    {
      title: t('renders.invoice.supplier'),
      dataIndex: 'supplierInvoiceRelation.name',
      render: (_, row: InvoiceWithOwner) => {
        return row.supplier?.name;
      },
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.invoiceAmount'),
      dataIndex: 'amount',
      defaultRender: 'currency',
      ...baseColumnSettings,
    },
    {
      width: 200,
      dataIndex: 'invoiceId',
      render: (id, row: InvoiceWithOwner) => (
        <>
          <ScopeTrackingButton scopeId={row.scopeId} />
          <DocumentViewModal id={id} idType="invoice" />
          <ResendButton
            invoiceId={id}
            onSuccess={onSuccess}
          />
          <SwitchChannelButton
            invoiceId={id}
            onSuccess={onSuccess}
          />
          {(row.type == InvoiceType.CreditNote || row.type == InvoiceType.Purchase) &&
            <SwitchInvoiceTypeButton
              invoice={row}
              onSuccess={onSuccess}
            />
          }
          <DeleteButton
            invoiceId={id}
            onSuccess={onSuccess}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Col>
        <DateFilter
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
        />
      </Col>
      <CustomTable
        rowKey="id"
        style={{ marginTop: '2rem' }}
        loading={isLoading || queryIsLoading}
        columns={tableColumns}
        dataSource={invoices}
        onChange={onTableChange}
        pagination={{
          current: filterOptions.page,
          pageSize: filterOptions.pageSize,
          hideOnSinglePage: true,
          total: total,
          onChange: (page, pageSize) => {
            setFilterOptions((cv) => ({ ...cv, page, pageSize }));
          },
        }}
        size="small"
      />
    </>
  );
};